<template>
<v-card class="post-card" outlined :to="{ name: 'post', params: { post_id: this.post.slug } }">
    <v-img class="white--text align-end font-weight-bold" :src="this.post.thumbnails" height="200px"/>
    <v-card-title class="text-left py-2" :style="{ height: '75px' }">{{ this.post.title }}</v-card-title>
    <v-card-text class="text-left" :style="{ height: '75px' }">{{ this.post.description }}</v-card-text>
    <v-card-actions />
</v-card>
</template>

<script>
export default {
    name: "PostCard",

    props: ["post"]
}
</script>

<style lang="scss">
.post-card .v-card__text {
    overflow: hidden;
}

.post-card .v-card__actions { 
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%; 
    text-align: center; 
    margin: 0; 
    padding: 30px 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1));
}
</style>
